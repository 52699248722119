@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
.App {
    text-align: center;
}

.ant-picker-dropdown {
    z-index: 1350 !important;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

html, body {
    margin: 0;
    padding: 0;
    /*font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif;*/
    font-size: 12px;
}

button {
    outline: none !important;
}

#external-events {
    z-index: 2;
    top: 20px;
    left: 20px;
    width: auto;
    padding: 0 10px;
    border: 4px solid #e7e5e5;
    background: #eee;
}

#external-events .fc-event {
    margin: 1em 0;
    cursor: move;
}

#calendar-container {
    position: relative;
    z-index: 1;
}

/*!
 * FullCalendar v4.0.0-alpha.3
 * Docs & License: https://fullcalendar.io/
 * (c) 2018 Adam Shaw
 */
.fc {
    direction: ltr;
    text-align: left;
}

.fc-rtl {
    text-align: right;
}

body .fc {
    /* extra precedence to overcome jqui */
    font-size: 1em;
}

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-highlight {
    /* when user is selecting cells */
    background: #bce8f1;
    opacity: .3;
}

.fc-bgevent {
    /* default look for background events */
    /*background: #8fdf82;*/
    opacity: .3;
}

.fc-nonbusiness {
    /* default look for non-business-hours areas */
    /* will inherit .fc-bgevent's styles */
    background: #d7d7d7;
}

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
    /* force height to include the border and padding */
    box-sizing: border-box;
    /* dimensions */
    margin: 0;
    height: 2.1em;
    padding: 0 .6em;
    /* text & cursor */
    font-size: 1em;
    /* normalize */
    white-space: nowrap;
    cursor: pointer;
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
    margin: 0;
    padding: 0;
}

.fc-state-default {
    /* non-theme */
    border: 1px solid;
}

.fc-state-default.fc-corner-left {
    /* non-theme */
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fc-state-default.fc-corner-right {
    /* non-theme */
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

/* icons in buttons */
.fc button .fc-icon {
    /* non-theme */
    position: relative;
    top: -0.05em;
    /* seems to be a good adjustment across browsers */
    margin: 0 .2em;
    vertical-align: middle;
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
/*.fc-state-default {*/
/*background-color: #f5f5f5;*/
/*background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);*/
/*background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));*/
/*background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);*/
/*background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);*/
/*background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);*/
/*background-repeat: repeat-x;*/
/*border-color: #e6e6e6 #e6e6e6 #bfbfbf;*/
/*border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);*/
/*color: #333;*/
/*text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);*/
/*box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }*/

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    color: #333333;
    background-color: #e6e6e6;
}

.fc-state-hover {
    color: #333333;
    text-decoration: none;
    background-position: 0 -15px;
    transition: background-position 0.1s linear;
}

.fc-state-down,
.fc-state-active {
    background-color: #e7e5e5;
    background-image: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-disabled {
    cursor: default;
    background-image: none;
    opacity: 0.65;
    box-shadow: none;
}

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
    display: inline-block;
}

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * {
    /* extra precedence b/c buttons have margin set to zero */
    float: left;
    margin: 0 0 0 -1px;
}

.fc .fc-button-group > :first-child {
    /* same */
    margin-left: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
    position: absolute;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.fc-popover .fc-header {
    /* TODO: be more consistent with fc-head/fc-body */
    padding: 2px 4px;
}

.fc-popover .fc-header .fc-title {
    margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
    cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
    float: left;
}

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
    float: right;
}

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
    border-style: solid;
    border-width: 1px;
}

hr.fc-divider {
    height: 0;
    margin: 0;
    /*padding: 0 0 2px;*/
    /* height is unreliable across browsers, so use padding */
    /*border-width: 1px 0;*/
}

.fc-clear {
    clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-mirror-skeleton {
    /* these element should always cling to top-left/right corners */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.fc-bg {
    bottom: 0;
    /* strech bg to bottom edge */
}

.fc-bg table {
    height: 100%;
    /* strech bg to bottom edge */
}

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
    width: 100%;
    box-sizing: border-box;
    /* fix scrollbar issue in firefox */
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.787vw;
    height: 100%;
    /* normalize cross-browser */
}

.fc th {
    text-align: center;
}

.fc th,
.fc td {
    border-style: solid;
    border-width: 1px;
    vertical-align: top;
    font-weight: 400;
}

td .fc-axis {
    border-style: none;
    font-weight: 400;
}


.fc td.fc-today {
    border-style: double;
    /* overcome neighboring borders */
}

.fc-sun {
    color: #ff867f !important;
}

/* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
a[data-goto] {
    cursor: pointer;
}

a[data-goto]:hover {
    text-decoration: underline;
}

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row {
    /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
    /* no visible border by default. but make available if need be (scrollbar width compensation) */
    border-style: solid;
    border-width: 0;
}

.fc-row table {
    /* don't put left/right border on anything within a fake row.
       the outer tbody will worry about this */
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;
    /* no bottom borders on rows */
    border-bottom: 0 hidden transparent;
}

.fc-row:first-child table {
    border-top: 0 hidden transparent;
    /* no top border on first row */
}

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
    position: relative;
}

.fc-row .fc-bg {
    z-index: 1;
}

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
    bottom: 0;
    /* stretch skeleton to bottom of row */
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
    height: 100%;
    /* stretch skeleton to bottom of row */
}

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
    border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
    z-index: 2;
}

.fc-row .fc-highlight-skeleton {
    z-index: 3;
}

/*
row content (which contains day/week numbers and events) as well as "mirror" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
    position: relative;
    z-index: 4;
    min-height: 40px;
    /*padding-bottom: 2px;*/
    /* matches the space above the events */
}

.fc-row .fc-mirror-skeleton {
    z-index: 5;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
    /* see-through to the background below */
    /* extra precedence to prevent theme-provided backgrounds */
    background: none;
    /* in case <td>s are globally styled */
    border-color: transparent;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-mirror-skeleton td {
    /* don't put a border between events and/or the day number */
    border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-mirror-skeleton tbody td {
    /* don't put a border between event cells */
    border-top: 0;
}

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
    -webkit-overflow-scrolling: touch;
}

/* TODO: move to agenda/basic */
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
    position: relative;
    /* re-scope all positions */
    width: 100%;

    overflow-y: hidden; /*Hidden scrollbar*/
    /* hack to force re-sizing this inner element when scrollbars appear/disappear */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
    position: relative;
    /* for resize handle and other inner positioning */
    display: block;
    /* make the <a> tag block */
    font-size: .85em;
    line-height: 1.3;
    border-radius: 0;
    border-style: solid;
    /*opacity: 0.5;*/
    /* default BORDER color */
}

a.fc-event{
    transition: 0s
}

.fc-event,
.fc-event-dot {
    background-color: #3a87ad;
    /* default BACKGROUND color */
}

.fc-event,
.fc-event:hover {
    color: #000000;
    /* default TEXT color */
    text-decoration: none;
    /* if <a> has an href */
}

.fc-event[href],
.fc-event.fc-draggable {
    cursor: pointer;
    /* give events with links and draggable events a hand mouse pointer */
}

.fc-not-allowed,
.fc-not-allowed .fc-event {
    /* to override an event's custom cursor */
    cursor: not-allowed;
}

.fc-event .fc-bg {
    /* the generic .fc-bg already does position */
    z-index: 1;
    background: #fff;
    opacity: .5;
}

.fc-event .fc-content {
    position: relative;
    height: 100%;
    z-index: 2;
}

/* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
.fc-event.fc-selected {
    z-index: 9999 !important;
    /* overcomes inline z-index */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Event Dragging
--------------------------------------------------------------------------------------------------*/
.fc-event.fc-dragging.fc-selected {
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}

.fc-event.fc-dragging:not(.fc-selected) {
    box-shadow: 5px 5px 3px black;
    opacity: .75;
}

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* bigger touch area when selected */
.fc-h-event.fc-selected:before {
    content: "";
    position: absolute;
    z-index: 3;
    /* below resizers */
    top: -10px;
    bottom: -10px;
    left: 0;
    right: 0;
}

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
    margin-left: 0;
    border-left-width: 0;
    padding-left: 1px;
    /* replace the border with padding */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
    margin-right: 0;
    border-right-width: 0;
    padding-right: 1px;
    /* replace the border with padding */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* resizer (cursor AND touch devices) */
/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
    cursor: w-resize;
    left: -1px;
    /* overcome border */
}

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
    cursor: e-resize;
    right: -1px;
    /* overcome border */
}

/* resizer (mouse devices) */
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
    width: 7px;
    top: -1px;
    /* overcome top border */
    bottom: -1px;
    /* overcome bottom border */
}

/* resizer (touch devices) */
.fc-h-event.fc-selected .fc-resizer {
    /* 8x8 little dot */
    border-radius: 4px;
    border-width: 4px;
    width: 6px;
    height: 6px;
    border-style: solid;
    border-color: inherit;
    background: #fff;
    /* vertically center */
    top: 50%;
    margin-top: -4px;
}

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
    margin-left: -4px;
    /* centers the 8x8 dot on the left edge */
}

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
    margin-right: -4px;
    /* centers the 8x8 dot on the right edge */
}

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-day-grid-event {
    margin: 1px 2px 0;
    /* spacing between events and edges */
    padding: 0 1px;
}

tr:first-child > td > .fc-day-grid-event {
    /*margin-top: 2px;*/
    /* a little bit more space before the first event */
}

.fc-mirror-skeleton tr:first-child > td > .fc-day-grid-event {
    margin-top: 0;
    /* except for mirror skeleton */
}

.fc-day-grid-event.fc-selected:after {
    content: "";
    position: absolute;
    z-index: 1;
    /* same z-index as fc-bg, behind text */
    /* overcome the borders */
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    /* darkening effect */
    background: #000;
    opacity: .25;
}

.fc-day-grid-event .fc-content {
    /* force events to be one-line tall */
    white-space: nowrap;
    overflow: hidden;
}

.fc-day-grid-event .fc-time {
    font-weight: bold;
}

/* resizer (cursor devices) */
/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
    margin-left: -2px;
    /* to the day cell's edge */
}

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
    margin-right: -2px;
    /* to the day cell's edge */
}

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
    margin: 1px 3px;
    font-size: .85em;
    cursor: pointer;
    text-decoration: none;
}

a.fc-more:hover {
    text-decoration: underline;
}

.fc-limited {
    /* rows and cells that are hidden because of a "more" link */
    display: none;
}

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
    z-index: 1;
    /* make the "more" popover one higher than this */
}

.fc-more-popover {
    z-index: 2;
    width: 220px;
}

.fc-more-popover .fc-event-container {
    padding: 10px;
}

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-now-indicator {
    position: absolute;
    border: 0 solid red;
}

/* Utilities
--------------------------------------------------------------------------------------------------*/
.fc-unselectable {
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
TODO: more distinction between this file and common.css
*/
/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
    border-color: #e7e5e5 !important;
}

.fc-unthemed .fc-popover {
    background-color: #fff;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
    background: #eee;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    color: #666;
}

.fc-unthemed td.fc-today {
    background: #fcf8e3;
}

.fc-unthemed .fc-disabled-day {
    background: #d7d7d7;
    opacity: .3;
}

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon {
    display: inline-block;
    height: 1em;
    line-height: 1em;
    font-size: 1em;
    text-align: center;
    overflow: hidden;
    font-family: "Courier New", Courier, monospace;
    /* don't allow browser text-selection */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

/*
Acceptable font-family overrides for individual icons:
  "Arial", sans-serif
  "Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon:after {
    position: relative;
}

.fc-icon-left-single-arrow:after {
    content: "\2039";
    font-weight: bold;
    font-size: 200%;
    top: -7%;
}

.fc-icon-right-single-arrow:after {
    content: "\203A";
    font-weight: bold;
    font-size: 200%;
    top: -7%;
}

.fc-icon-left-double-arrow:after {
    content: "\AB";
    font-size: 160%;
    top: -7%;
}

.fc-icon-right-double-arrow:after {
    content: "\BB";
    font-size: 160%;
    top: -7%;
}

.fc-icon-left-triangle:after {
    content: "\25C4";
    font-size: 125%;
    top: 3%;
}

.fc-icon-right-triangle:after {
    content: "\25BA";
    font-size: 125%;
    top: 3%;
}

.fc-icon-down-triangle:after {
    content: "\25BC";
    font-size: 125%;
    top: 2%;
}

.fc-icon-x:after {
    content: "\D7";
    font-size: 200%;
    top: 6%;
}

canvas {
    width: 100% !important;
    height: auto !important;
}

#chart {
    position: relative;
    height: 400px;
    width: 400px;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-popover {
    border-width: 1px;
    border-style: solid;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    font-size: .9em;
    margin-top: 2px;
}

.fc-unthemed {
    height: 100% !important;
}

.fc-timeGrid-view {
    height: 100% !important;
}

.fc-time-grid-container {
    height: calc(100% - 54px) !important;
    min-height: 305px !important;
    /*min-height: auto !important;*/
}

#calendarRoot .fc-time-grid-container {
    height: calc(100% - 54px) !important;
    /*min-height: 305px !important;*/
    min-height: auto !important;
    overflow: auto !important;
}

/*
#calendar-container0 .fc-view-container {
    overflow-y: auto;
}
*/


.fc-time-grid {
    height: 100% !important;
}

#calendarRoot.fc-time-grid-container, #calendarRoot .fc-time-grid {
    height: 100%;
    /*overflow: hidden;*/
}

.fc-slats {
    height: 100% !important;
}

.resize-calendar {
    min-height: calc(100% - 10px) !important;
}

/* List View
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-list-item:hover td {
    background-color: #f5f5f5;
}

/* Colors
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-disabled-day {
    background-image: none;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover > .ui-widget-header + .ui-widget-content {
    border-top: 0;
    /* where they meet, let the header have the border */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-event {
    /* overpower jqui's styles on <a> tags. TODO: more DRY */
    color: #fff;
    /* default TEXT color */
    text-decoration: none;
    /* if <a> has an href */
    /* undo ui-widget-header bold */
    font-weight: normal;
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.ui-widget td.fc-axis {
    font-weight: normal;
    /* overcome bold */
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats .ui-widget-content {
    background: none;
    /* see through to fc-bg */
}

.fc.fc-bootstrap3 a {
    text-decoration: none;
}

.fc.fc-bootstrap3 a[data-goto]:hover {
    text-decoration: underline;
}

.fc-bootstrap3 hr.fc-divider {
    border-color: inherit;
}

.fc-bootstrap3 .fc-today.alert {
    border-radius: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-popover .panel-body {
    padding: 0;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-time-grid .fc-slats table {
    /* some themes have background color. see through to slats */
    background: none;
}

.fc.fc-bootstrap4 a {
    text-decoration: none;
}

.fc.fc-bootstrap4 a[data-goto]:hover {
    text-decoration: underline;
}

.fc-bootstrap4 hr.fc-divider {
    border-color: inherit;
}

.fc-bootstrap4 .fc-today.alert {
    border-radius: 0;
}

.fc-bootstrap4 a.fc-event:not([href]):not([tabindex]) {
    color: #fff;
}

.fc-bootstrap4 .fc-popover.card {
    position: absolute;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-popover .card-body {
    padding: 0;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-time-grid .fc-slats table {
    /* some themes have background color. see through to slats */
    background: none;
}

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
    text-align: center;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em;
}

.fc-toolbar.fc-footer-toolbar {
    margin-top: 1em;
}

.fc-toolbar .fc-left {
    float: left;
}

.fc-toolbar .fc-right {
    float: right;
}

.fc-toolbar .fc-center {
    display: inline-block;
}

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
    /* extra precedence to override button border margins */
    float: left;
    margin-left: .75em;
}

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child {
    /* extra precedence to override button border margins */
    margin-left: 0;
}

/* title text */
.fc-toolbar h2 {
    margin: 0;
}

/* button layering (for border precedence) */
.fc-toolbar button {
    position: relative;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
    z-index: 2;
}

.fc-toolbar .fc-state-down {
    z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
    z-index: 4;
}

.fc-toolbar button:focus {
    z-index: 5;
}

/* View Structure
--------------------------------------------------------------------------------------------------*/
.fc-view-container {
    position: relative;
    height: 100%;
    /*height: calc(100vh - 155px)*/
}

/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
    box-sizing: content-box;
}

.fc-view,
.fc-view > table {
    /* so dragged elements can be above the view's main element */
    position: relative;
    z-index: 1;
}

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
    /* there may be week numbers in these views, so no padding-top */
    padding-bottom: 1em;
    /* ensure a space at bottom of cell for user selecting/clicking */
}

.fc-basic-view .fc-body .fc-row {
    min-height: 4em;
    /* ensure that all rows are at least this tall */
}

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
    overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

/* week and day number styling */
.fc-day-top.fc-other-month {
    opacity: 0.3;
}

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
    padding: 2px;
}

.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number {
    padding: 0 2px;
    /* column headers can't have as much v space */
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    float: right;
}

.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
    float: left;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
    float: left;
    border-radius: 0 0 3px 0;
}

.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
    float: right;
    border-radius: 0 0 0 3px;
}

.fc-basic-view .fc-day-top .fc-week-number {
    min-width: 1.5em;
    text-align: center;
    background-color: #f2f2f2;
    color: #808080;
}

/* when week/day number have own column */
.fc-basic-view td.fc-week-number {
    text-align: center;
}

.fc-basic-view td.fc-week-number > * {
    /* work around the way we do column resizing and ensure a minimum width */
    display: inline-block;
    min-width: 1.25em;
}

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-agenda-view .fc-day-grid {
    position: relative;
    z-index: 2;
    width: 100%;
    /* so the "more.." popover will be over the time grid */
}

.fc-agenda-view .fc-day-grid .fc-row {
    min-height: 3em;
    /*width:100%;*/
    /* all-day section will never get shorter than this */
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
    padding-bottom: 1em;
    /* give space underneath events for clicking/selecting days */
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc-axis {
    width: 6% !important;
    border-style: none;
    position: relative;
}

.fc .fc-axis {
    /* .fc to overcome default cell styles */
    vertical-align: top;
    padding: 0 4px;
    border: none;

    white-space: nowrap;
}

.fc-ltr .fc-axis {
    text-align: center;
    font-size: 9px;
    white-space: pre-wrap;
}

.fc-rtl .fc-axis {
    text-align: left;
}

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid {
    /* so slats/bg/content/etc positions get scoped within here */
    position: relative;
    z-index: 1;
}

.fc-time-grid {
    min-height: 100%;
    /* so if height setting is 'auto', .fc-bg stretches to fill height */
}

.fc-time-grid table {
    /* don't put outer borders on slats/bg/content/etc */
    border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
    z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
    /* the <hr> AgendaView injects when grid is shorter than scroller */
    position: relative;
    z-index: 2;
}

.fc-time-grid .fc-content-col {
    position: relative;
    /* because now-indicator lives directly inside */
}

.fc-time-grid .fc-content-skeleton {
    position: absolute;
    /*min-height: 50px;*/
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
}

/* divs within a cell within the fc-content-skeleton */
.fc-time-grid .fc-business-container {
    position: relative;
    z-index: 1;
}

.fc-time-grid .fc-bgevent-container {
    position: relative;
    z-index: 2;
}

.fc-time-grid .fc-highlight-container {
    position: relative;
    z-index: 3;
}

.fc-time-grid .fc-event-container {
    position: relative;
    z-index: 4;
}

.fc-time-grid .fc-now-indicator-line {
    z-index: 5;
}

.fc-time-grid .fc-mirror-container {
    /* also is fc-event-container */
    position: relative;
    z-index: 6;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
    height: 0.8em;
    /*border-bottom: 0;*/
    position: relative;
    font-size: 0px;
    font-weight: bold;
    /*margin-top: 10px;*/
    /* each cell is responsible for its top border */
}

.fc-event {
    page-break-inside: avoid;
}

.fc-time-grid .fc-slats td span {
    /*bottom: 60%;*/
    right: 10px;
    position: absolute;
    font-size: 0.7rem;
    top: -2px;
    /* each cell is responsible for its top border */
}

tbody .fc-axis .fc-time .fc-widget-content:first-child {
    display: none;
}

.fc-widget-content {
    padding-bottom: 0px;
}

.fc-time-grid .fc-slats .fc-minor td {
    border-top-style: dotted;
}

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container {
    /* a div within a cell within the fc-highlight-skeleton */
    position: relative;
    /* scopes the left/right of the fc-highlight to be in the column */
}

.fc-time-grid .fc-highlight {
    position: absolute;
    left: 0;
    right: 0;
    /* top and bottom will be in by JS */
}

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
/*.fc-ltr .fc-time-grid .fc-event-container {*/
/*!* space on the sides of events for LTR (default) *!*/
/*margin: 0 2.5% 0 2px; }*/

/*.fc-rtl .fc-time-grid .fc-event-container {*/
/*!* space on the sides of events for RTL *!*/
/*margin: 0 2px 0 2.5%; }*/

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
    position: absolute;
    z-index: 1;
    /* scope inner z-index's */
}

.fc-time-grid .fc-bgevent {
    /* background events always span full width */
    left: 0;
    right: 0;
}

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start {
    /* events that are continuing from another day */
    /* replace space made by the top border with padding */
    border-top-width: 0;
    padding-top: 1px;
    /* remove top rounded corners */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.fc-v-event.fc-not-end {
    /* replace space made by the top border with padding */
    border-bottom-width: 0;
    padding-bottom: 1px;
    /* remove bottom rounded corners */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
    overflow: hidden;
    /* don't let the bg flow over rounded corners */
}

.fc-time-grid-event.fc-selected {
    /* need to allow touch resizers to extend outside event's bounding box */
    /* common fc-selected styles hide the fc-bg, so don't need this anyway */
    overflow: hidden;
}

.fc-time-grid-event.fc-selected .fc-bg {
    display: none;
    /* hide semi-white background, to appear darker */
}

.fc-time-grid-event .fc-content {
    overflow: hidden;
    /* for when .fc-selected */
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-time {
    font-size: .85em;
}

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
    /* don't wrap to second line (now that contents will be inline) */
    white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
    /* put the time and title on the same line */
    display: inline-block;
    vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
    display: none;
    /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before {
    content: attr(data-start);
    /* ...instead, display only the start time */
}

.fc-time-grid-event.fc-short .fc-time:after {
    content: "\A0-\A0";
    /* seperate with a dash, wrapped in nbsp's */
}

.fc-time-grid-event.fc-short .fc-title {
    font-size: .85em;
    /* make the title text the same size as the time */
    padding: 0;
    /* undo padding from above */
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
    border-color: #e7e5e5 !important;
}

/* resizer (cursor device) */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    overflow: hidden;
    line-height: 8px;
    font-size: 11px;
    font-family: monospace;
    text-align: center;
    cursor: s-resize;
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
    content: "=";
}

/* resizer (touch device) */
.fc-time-grid-event.fc-selected .fc-resizer {
    /* 10x10 dot */
    border-radius: 5px;
    border-width: 1px;
    width: 8px;
    height: 8px;
    border-style: solid;
    border-color: inherit;
    background: #fff;
    /* horizontally center */
    left: 50%;
    margin-left: -5px;
    /* center on the bottom edge */
    bottom: -5px;
}

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-now-indicator-line {
    border-top-width: 2px;
    left: 0;
    right: 0;
}

/* arrow on axis */
.fc-time-grid .fc-now-indicator-arrow {
    margin-top: -5px;
    /* vertically center on top coordinate */
}

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
    left: 0;
    /* triangle pointing right... */
    border-width: 5px 0 5px 6px;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
    right: 0;
    /* triangle pointing left... */
    border-width: 5px 6px 5px 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

/* List View
--------------------------------------------------------------------------------------------------*/
/* possibly reusable */
.fc-event-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

/* view wrapper */
.fc-rtl .fc-list-view {
    direction: rtl;
    /* unlike core views, leverage browser RTL */
}

.fc-list-view {
    border-width: 1px;
    border-style: solid;
}

/* table resets */
.fc .fc-list-table {
    table-layout: auto;
    /* for shrinkwrapping cell content */
}

.fc-list-table td {
    border-width: 1px 0 0;
    padding: 8px 14px;
}

.fc-list-table tr:first-child td {
    border-top-width: 0;
}

/* day headings with the list */
.fc-list-heading {
    border-bottom-width: 1px;
}

.fc-list-heading td {
    font-weight: bold;
}

.fc-ltr .fc-list-heading-main {
    float: left;
}

.fc-ltr .fc-list-heading-alt {
    float: right;
}

.fc-rtl .fc-list-heading-main {
    float: right;
}

.fc-rtl .fc-list-heading-alt {
    float: left;
}

/* event list items */
.fc-list-item.fc-has-url {
    cursor: pointer;
    /* whole row will be clickable */
}

.fc-list-item-marker,
.fc-list-item-time {
    white-space: nowrap;
    width: 1px;
}

/* make the dot closer to the event title */
.fc-ltr .fc-list-item-marker {
    padding-right: 0;
}

.fc-rtl .fc-list-item-marker {
    padding-left: 0;
}

.fc-list-item-title a {
    /* every event title cell has an <a> tag */
    text-decoration: none;
    color: inherit;
}

.fc-list-item-title a[href]:hover {
    /* hover effect only on titles with hrefs */
    text-decoration: underline;
}

/* message when no events */
.fc-list-empty-wrap2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.fc-list-empty-wrap1 {
    width: 100%;
    height: 100%;
    display: table;
}

.fc-list-empty {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.fc-unthemed .fc-list-empty {
    /* theme will provide own background */
    background-color: #eee;
}

@media print {
    .fc {
        max-width: 100% !important;
    }

    /* Global Event Restyling
  --------------------------------------------------------------------------------------------------*/
    .fc-event {
        background: #fff !important;
        color: #000 !important;
        page-break-inside: avoid;
    }

    .fc-event .fc-resizer {
        display: none;
    }

    /* Table & Day-Row Restyling
  --------------------------------------------------------------------------------------------------*/
    .fc th,
    .fc td,
    .fc hr,
    .fc thead,
    .fc tbody,
    .fc-row {
        border-color: #e7e5e5 !important;
        background: #fff !important;
    }

    /* kill the overlaid, absolutely-positioned components */
    /* common... */
    .fc-bg,
    .fc-bgevent-skeleton,
    .fc-highlight-skeleton,
    .fc-mirror-skeleton,
    .fc-bgevent-container,
    .fc-business-container,
    .fc-highlight-container,
    .fc-mirror-container {
        display: none;
    }

    /* don't force a min-height on rows (for DayGrid) */
    .fc tbody .fc-row {
        height: auto !important;
        /* undo height that JS set in distributeHeight */
        min-height: 0 !important;
        /* undo the min-height from each view's specific stylesheet */
    }

    .fc tbody .fc-row .fc-content-skeleton {
        position: static;
        /* undo .fc-rigid */
        padding-bottom: 0 !important;
        /* use a more border-friendly method for this... */
    }

    .fc tbody .fc-row .fc-content-skeleton tbody tr:last-child td {
        /* only works in newer browsers */
        padding-bottom: 1em;
        /* ...gives space within the skeleton. also ensures min height in a way */
    }

    .fc tbody .fc-row .fc-content-skeleton table {
        /* provides a min-height for the row, but only effective for IE, which exaggerates this value,
         making it look more like 3em. for other browers, it will already be this tall */
        height: 1em;
    }

    /* Undo month-view event limiting. Display all events and hide the "more" links
  --------------------------------------------------------------------------------------------------*/
    .fc-more-cell,
    .fc-more {
        display: none !important;
    }

    .fc tr.fc-limited {
        display: table-row !important;
    }

    .fc td.fc-limited {
        display: table-cell !important;
    }

    .fc-popover {
        display: none;
        /* never display the "more.." popover in print mode */
    }

    /* TimeGrid Restyling
  --------------------------------------------------------------------------------------------------*/
    /* undo the min-height 100% trick used to fill the container's height */
    .fc-time-grid {
        min-height: 0 !important;
    }

    /* don't display the side axis at all ("all-day" and time cells) */
    .fc-agenda-view .fc-axis {
        display: none;
    }

    /* don't display the horizontal lines */
    .fc-slats,
    .fc-time-grid hr {
        /* this hr is used when height is underused and needs to be filled */
        display: none !important;
        /* important overrides inline declaration */
    }

    /* let the container that holds the events be naturally positioned and create real height */
    .fc-time-grid .fc-content-skeleton {
        position: static;
    }

    /* in case there are no events, we still want some height */
    .fc-time-grid .fc-content-skeleton table {
        height: 4em;
    }

    /* kill the horizontal spacing made by the event container. event margins will be done below */
    .fc-time-grid .fc-event-container {
        margin: 0 !important;
    }

    /* TimeGrid *Event* Restyling
  --------------------------------------------------------------------------------------------------*/
    /* naturally position events, vertically stacking them */
    .fc-time-grid .fc-event {
        position: static !important;
        margin: 3px 2px !important;
    }

    /* for events that continue to a future day, give the bottom border back */
    .fc-time-grid .fc-event.fc-not-end {
        border-bottom-width: 1px !important;
    }

    /* indicate the event continues via "..." text */
    .fc-time-grid .fc-event.fc-not-end:after {
        content: "...";
    }

    /* for events that are continuations from previous days, give the top border back */
    .fc-time-grid .fc-event.fc-not-start {
        border-top-width: 1px !important;
    }

    /* indicate the event is a continuation via "..." text */
    .fc-time-grid .fc-event.fc-not-start:before {
        content: "...";
    }

    /* time */
    /* undo a previous declaration and let the time text span to a second line */
    .fc-time-grid .fc-event .fc-time {
        white-space: normal !important;
    }

    /* hide the the time that is normally displayed... */
    .fc-time-grid .fc-event .fc-time span {
        display: none;
    }

    /* ...replace it with a more verbose version (includes AM/PM) stored in an html attribute */
    .fc-time-grid .fc-event .fc-time:after {
        content: attr(data-full);
    }

    /* Vertical Scroller & Containers
  --------------------------------------------------------------------------------------------------*/
    /* kill the scrollbars and allow natural height */
    .fc-scroller,
    .fc-day-grid-container,
    .fc-time-grid-container {
        /* */
        /*height: 100%;*/
        /*overflow: hidden !important;*/
    }

    /* kill the horizontal border/padding used to compensate for scrollbars */
    .fc-row {
        border: 0 !important;
        margin: 0 !important;
    }

    /* Button Controls
  --------------------------------------------------------------------------------------------------*/
    .fc-button-group,
    .fc button {
        display: none;
        /* don't display any button-related controls */
    }
}

.pac-container {
    z-index: 9999 !important;
}

.div-charts {
    max-height: 70px;
}


/* header mobile */
/*.headerLeft{
    width: 12.5%;
}

@media only screen and (max-width: 600px) {
    .headerLeft{
        width: 50px;
    }
}*/


.div-calendar .fc-time-grid .fc-slats tr td:last-child {
    position: relative;
    cursor: pointer;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: dashed 1px #eaeaea;
    height: 1px;
}

.div-calendar .fc-widget-content {
    /*overflow-y: auto;*/
    height: 100%;
}


#calendarRoot .fc-time-grid-container .fc-slats tr:hover {
    background: #fcf8e3;
}

table.fc-agenda-slots td.fc-widget-content {
    border-right: 1px #DDD solid;
}

table.fc-agenda-slots td.fc-widget-content:hover {
    background-color: #F1F1F1;
}

.clearfix {
    /*overflow: auto;*/
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

#meeting .fc-unthemed td {
    border-color: #fff;
    z-index: 2;
    opacity: 1;
}

#time .DateRangePicker {
    z-index: 10;
}

#time .DateRangePickerInput__withBorder {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#time .DateInput_input__small {
    font-size: 1rem;
    color: #000;
}

#time .DateInput__small {
    width: auto !important;
}

#time .DateInput_input__focused {
    outline: none;
    color: #000;
}

.editVote .daterangepicker {
    position: static;
}

.editVote input {
    width: 100%;
    padding: 5px;
    outline: none;
    text-align: center;
}

.meetingSuccses {
    -webkit-animation: succes 1s infinite;
            animation: succes 1s infinite;
    font-size: 13px;
    display: inline-block;


}

@-webkit-keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

@keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

.timeDrag {
    display: none;
}

.fc-mirror .timeDrag {
    display: block;
    font-size: 7px;
}

.colorWhite {
    color: #fff;
}

.colorWhite svg {
    max-width: 15px;
    max-height: 15px;
}

.colorBlack {
    color: #000
}

.colorBlack svg {
    max-width: 15px;
    max-height: 15px;
}

/*svg {*/
/*    max-width: 15px;*/
/*}*/

.div-calendar .fc-body > tr {
    overflow-y: hidden;
}

.div-calendar .fc-body > tr > td {
    border: none;
}

.timeCalendar {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.timeCalendar .about {
    margin: 0 10px;
}

.timeCalendar .bp3-input-group {
    width: 205px;
}

.scrollLeftEvent {
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 84px;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    padding: 0px 10px 10px 0;
    /*background: #f7f7f7;*/
    box-sizing: border-box;
}

.leftEventMobile {
    position: static;
    min-width: auto;
}

.scrollLeftEvent:hover {
    overflow: auto;

}

.scrollLeftEvent::-webkit-scrollbar {
    display: none
}

.contentEvent {
    overflow-y: hidden;
    margin-left: 16.666667%;
    overflow-x: auto;
}

.contentEventMobile {
    margin-left: 0px;
    overflow: auto;
}

.hiddenDisplay {
    display: none;
}

.titleChat {
    line-height: 25px;
    background: #1a73e8;
    color: #fff;
    width: 350px;
    text-align: left;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    position: relative;
    display: flex;
}

.titleChatFocus {
    line-height: 25px;
    background: #1a73e8;
    color: #fff;
    width: 350px;
    text-align: left;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    position: relative;
    display: flex;
    animation: colorBackground 1.5s ease-out infinite;
    -moz-animation: colorBackground 1.5s ease-out infinite;
    -webkit-animation: colorBackground 1.5s ease-out infinite;
    -o-animation: colorBackground 1.5s ease-out infinite;

}

@-webkit-keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@media screen and (max-width: 1400px) {
    .scrollLeftEvent {
        min-width: 260px;
    }

    .contentEvent {
        margin-left: 260px;
    }
}

@media screen and (max-width: 1200px) {


    .timeCalendar .bp3-input-group {
        width: auto;
    }
}

@media screen and (max-width: 960px) {
    .DateRangePicker_picker__directionLeft {
        left: 0 !important;
    }

    .titleChat {
        width: 250px;
    }

    .titleChatFocus {
        width: 250px;
    }

    .timeCalendar {
        /*flex-direction: column;*/
    }

    .timeCalendar .about {
        margin: 0 3px;
    }
}

@media screen and (max-width: 600px) {
    .leftEventMobile, .scrollLeftEvent {
        position: static;
        min-width: auto;
    }

    .contentEvent {
        margin-left: 0px;
    }

    .timeCalendar {
        flex-direction: column;
    }

}

#calendarRoot .fc-content-skeleton {
    padding-bottom: 0;
}

.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    height: auto !important;
}

.smooth-dnd-container.horizontal {
    display: flex !important;
    flex-wrap: wrap;
}

body {
    /*font-family: 'Montserrat', sans-serif !important;*/
    color: #000000;
    line-height: 1.3;
}

.fontMontserrat {
    /*font-family: 'Montserrat', sans-serif !important;*/
}

.MuiTypography-root {
    font-family: 'Montserrat', sans-serif !important;
}

.fontBebasNeue {
    font-family: 'Bebas Neue', cursive;
}

.isSun {
    color: #ff867f;
}

.isSat {
    color: #1B97F0;
}

.headerCalendar {
    font-weight: bold;
    position: relative;
    border: solid 1px transparent;

}

.isSelection {
    border: solid 1px red;
}

.material-icons-fix {
    vertical-align: middle;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:hover {
    background: #fcf8e3;
}

.div-calendar .fc-time-grid > .fc-bg {
    z-index: 2 !important;
}

.div-calendar .fc-time-grid > .fc-bg .fc-day {
    cursor: pointer;
}

.headerCalendar {
    cursor: pointer;
}

.popNotification {
    position: absolute;
    top: 10px;
    right: 50px
}

.popNotification button:hover {
    background-color: unset;
}


.ant-btn-clicked:after {
    -webkit-animation: none !important;
    animation: none !important;
}

[ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    animation: none !important;
}

/* ck editor */
.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners {
    z-index: 9999 !important;
}

.menu-dialog .MuiToolbar-regular {
    width: 98%;
}


.flag-icon-jp {
    width: 30px;
    height: 30px;
    margin: 2px;
    cursor: pointer;
}

.flag-icon .flag-icon-jp span:hover {
    cursor: pointer;
    opacity: 0.8;
}

.flag-icon-us {
    width: 30px;
    height: 30px;
    margin: 2px;
    cursor: pointer;

}

.flag-icon .flag-icon-us span:hover {
    cursor: pointer;
    opacity: 0.8;
}

.DateRangePickerInput__withBorder {
    width: 350px !important;
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.fromDateHourContainer {
    text-align: center;
}

.timeSelect {
    text-align: center;
    font-size: 1.1rem;
    border: solid 1px #ebebeb;
    padding: 5px;
    cursor: pointer;
}

#time-popover #buttonContainer {
    position: absolute !important;
    right: 65px;
    margin: 0;
    bottom: 4px;
}

#time-popover .cancelBtn {
    position: absolute;
    right: 0;
    bottom: 4px;
    background: #989595;
    text-transform: capitalize;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
    padding: 7px 10px;
    cursor: pointer;
    margin-right: 4px;
    border: none;
}

#time-popover .cancelBtn:hover {
    background: #808080;
}

#time-popover .MuiPopover-root {
    background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 960px) {
    #time-popover .fromDateTimeContainer {
        position: relative;
    }

    #time-popover .cancelBtn {
        position: static !important;
        z-index: 10;
        margin: 10px;
    }

    #time-popover #buttonContainer {
        z-index: 10;
        position: static !important;
        float: left !important;

    }

    #time-popover .activeNotifier {
        padding-bottom: 10px;
    }

    #time-popover .divCancelBtn {
        text-align: right;
        margin-top: -37px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bold {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.light {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.medium {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.normal {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.regular {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.react-datepicker__input-container input {
    border: none !important;
    width: 100% !important;
}

.collection-create-form_last-form-item {
    margin-bottom: 0;
}

.btnPrimary {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    border-bottom: solid 3px #79b369 !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnPrimary:active {
    -webkit-transform: translateY(3px) !important;
            transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnPrimary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

div[tabindex="-1"]:focus {
    outline: 0;
}

.clf-ant-relative {
    position: relative;
}

.rateOfTask circle {
    display: none;
}

.rateOfTask {
    height: 36px;
}

.w100 {
    width: 100%;
}

.fl-l {
    float: left;
}

.fl-r {
    float: right;
}

.ant-image-preview-img-wrapper img {
    border-radius: 10px !important;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    /*src: url(https://example.com/MaterialIcons-Regular.eot); !* For IE6-8 *!*/
    src: local('Material Icons'),
    local('MaterialIcons-Regular')
    /*url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),*/
    /*url(https://example.com/MaterialIcons-Regular.woff) format('woff'),*/
    /*url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');*/
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.taskImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.taskImgContainer {
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    background-color: grey;
    border-radius: 50%;
    margin-top: 2px;
}

.taskImgEvt {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.fw700 {
    font-weight: 700;
}

.px14 {
    font-size: 0.875rem;
    line-height: 1rem;
}

.brd20 {
    border-radius: 1.25rem;
}

.clf-flex {
    display: flex;
}

.clf-text-center {
    text-align: center;
}

.clf-flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.clf-grid-center {
    display: grid;
    justify-content: center;
    align-items: center;
}

.clf-block-center {
    display: block;
    justify-content: center;
    align-items: center;
}

.clf-space-between {
    width: 100%;
    justify-content: space-between;
    /*max-width: 210px;*/
}

.clf-bebas-neue {

}

.clf-left-10 {
    margin-left: 10px;
}

.clf-right-10 {
    margin-right: 10px;
}

.clf-cursor-pointer {
    cursor: pointer;
}

.clf-no-border {
    border: none !important;
}

.clf-input {
    width: 50px !important;
}

.clf-input input {
    font-size: 16px;
    font-weight: bold;
}

.clf-svg-color {
    fill: currentColor;
}

.circle-picker {
    justify-content: center;
}

.clf-padding3 {
    padding: 3px !important;
}

@media (max-width: 1600px) {
    .clf-space-between {
        max-width: 210px;
        justify-content: flex-start;
    }
}

.clf-montserrat {
    font-family: Montserrat sans-serif !important;
}

.hover-show-tooltip .ant-tooltip-inner {
    background-color: #F1F1F1;
    border-radius: 5px;
    padding: 5px;
    box-shadow: unset;
}

.hover-show-tooltip .ant-tooltip-arrow-content {
    background-color: #F1F1F1;
}

.hover-show-tooltip .ant-tooltip-content {
    min-width: 200px;
}

.hover-show-tooltip-2 .ant-tooltip-content {
    min-width: 130px !important;
}

.dropdown-nums .ant-dropdown-menu-items {
    padding: 0;
}

