body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bold {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.light {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.medium {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.normal {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.regular {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.react-datepicker__input-container input {
    border: none !important;
    width: 100% !important;
}

.collection-create-form_last-form-item {
    margin-bottom: 0;
}

.btnPrimary {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    border-bottom: solid 3px #79b369 !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnPrimary:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnPrimary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

div[tabindex="-1"]:focus {
    outline: 0;
}

.clf-ant-relative {
    position: relative;
}

.rateOfTask circle {
    display: none;
}

.rateOfTask {
    height: 36px;
}

.w100 {
    width: 100%;
}

.fl-l {
    float: left;
}

.fl-r {
    float: right;
}

.ant-image-preview-img-wrapper img {
    border-radius: 10px !important;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    /*src: url(https://example.com/MaterialIcons-Regular.eot); !* For IE6-8 *!*/
    src: local('Material Icons'),
    local('MaterialIcons-Regular')
    /*url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),*/
    /*url(https://example.com/MaterialIcons-Regular.woff) format('woff'),*/
    /*url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');*/
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.taskImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.taskImgContainer {
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    background-color: grey;
    border-radius: 50%;
    margin-top: 2px;
}

.taskImgEvt {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
